@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

iframe#webpack-dev-server-client-overlay{display:none!important}

.rating-stars>svg:hover,
.rating-stars svg:hover~svg {
    fill: #fbbe24;
}
